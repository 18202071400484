let button;

const review = new Swiper('.review__slider', {
    slidesPerView: 1,
    spaceBetween: 120,
    centeredSlides: true,
    loop: true,
    navigation: {
        nextEl: ".review__next",
        prevEl: ".review__prev"
    }
});
const validateForm = (form, func) => {
    form.on("submit", function (e) {
        e.preventDefault();
    });
    $.validator.addMethod("goodMessage", function (value, element) {
        return this.optional(element) || /^[\sаА-яЯіІєЄїЇґҐa-zA-Z0-9._-]{2,100}$/i.test(value);
    }, "Please enter correct");

    $.validator.addMethod("goodEmail", function (value, element) {
        return this.optional(element) || /^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,62}$/i.test(value);
    }, "Please enter correct email");


    form.validate({
        rules: {
            name: {
                required: true,
                goodMessage: true,
            },
            email: {
                required: true,
                goodEmail: true,
                email: true
            },
            phone: {
                required: true,
            },

        },
        messages: {
            name: {
                required: "This field is required",
                email: "Please enter correct username"
            },
            email: {
                required: "This field is required",
                email: "Please enter correct email"
            },
            phone: {
                required: "This field is required",
            },

        },
        submitHandler: function () {
            func();
            form[0].reset();
        }
    });
};

function ajaxSend(form, funcSuccess,funcError ) {
    let data = form.serialize();
    $.ajax({
        url: '/wp-admin/admin-ajax.php',
        data: data,
        method: 'POST',
        success: function (res) {
            console.log('success ajax');
            funcSuccess(res)
        },
        error: function (error) {
            console.log('error ajax');
            funcError(error)
        },
        complete: function (){

        }
    });
}


function appendStar() {
    $('.review__star').each(function () {
        let count_star = $(this).data('star');
        let fullStar = `<img src="./../img/star.svg"/>`;
        for (let i = 0; i < count_star; i++) {
                $(this).append(fullStar);
            }
    });
}




function changeMob() {
    if(window.innerWidth <= 666){
    }
}







function showModal(modal) {
    modal.show();
    $('body').css('overflow', 'hidden');

    $('.modal__close').click(function () {
        $(this).closest(modal).hide();
        $('body').css('overflow', 'visible');
        return false;
    });
    $('.modal__btn-close').click(function () {
        $(this).closest(modal).hide();
        $('body').css('overflow', 'visible');
        return false;
    });
    $(document).keydown(function (e) {
        if (e.keyCode === 27) {
            e.stopPropagation();
            $('body').css('overflow', 'visible');
        }

    });
    modal.click(function (e) {
        if ($(e.target).closest('.modal__content').length == 0) {
            $(this).hide();
            $('body').css('overflow', 'visible');
        }
    });
}





const openMenu = () => {
    $('.header__burger').toggleClass("header__burger-open");
    $('.header__menu').toggleClass('header__menu-show');
    $('body').toggleClass('hidden');
};



$(document).ready(function(){
    let contactForm = $('.contact__form');
    validateForm(contactForm, function () {
        ajaxSend(contactForm, function (){
            showModal($('.modal__contact'))},function (){
                showModal($('.modal__contact'))}
        )
    });

    $('.header__burger').on('click', openMenu);
    appendStar()
});

$(window).load(function(){

});

$(window).resize(function(){

});
$(window).scroll(function () {
});
